import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import users from './modules/users';
import recebimento from './modules/recebimentos';
import fila from './modules/fila';
import producao from './modules/producao';
import estoque from './modules/estoque';
import aberturalote from './modules/aberturalote';
import visitantes from './modules/visitantes';
import empresa from './modules/empresa';
import permissao from './modules/permissao';
import controlesequencia from './modules/controlesequencia';
import contrato from './modules/contrato';
import invoice from './modules/invoice';
import modulos from './modules/modulos';
import documentopagar from './modules/documentopagar';
import documentoreceber from './modules/documentoreceber';
import feriado from './modules/feriado';
import imagem from './modules/imagem';
import aberturaloteanalise from './modules/aberturaloteanalise';
import ordemservico from './modules/ordemservico';
import apontamentoproducao from './modules/apontametoproducao';
import modelo from './modules/modelo';
import contacorrente from './modules/contacorrente';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async $_setup({ dispatch }) {
      await dispatch('auth/$_setup');
    },
  },

  modules: {
    auth,
    users,
    recebimento,
    fila,
    producao,
    estoque,
    aberturalote,
    visitantes,
    empresa,
    permissao,
    controlesequencia,
    contrato,
    invoice,
    modulos,
    documentopagar,
    documentoreceber,
    feriado,
    imagem,
    aberturaloteanalise,
    ordemservico,
    apontamentoproducao,
    modelo,
    contacorrente,
  },
});
