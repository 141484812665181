import Vue from 'vue';
import VueRouter from 'vue-router';

import vuetify from '@/plugins/vuetify';

import * as ROUTES from '@/constants/ROUTES';

import {
  layoutRoute, viewRoute, redirectRoute, requireAuthenticated, requireUnauthenticated,
} from '../utils/router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    } else if (to.name === from.name) {
      scrollTo = document.documentElement.scrollTop;
    }

    return vuetify.framework.goTo(scrollTo);
  },

  routes: [
    layoutRoute(ROUTES.ROUTE_SIGN_IN.path, 'base', [
      viewRoute({
        name: ROUTES.ROUTE_SIGN_IN.name,
        view: 'sign-in',
      }, requireUnauthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_HOME.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_HOME.name,
        view: 'home',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_VISITANTES.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_VISITANTES.name,
        view: 'visitantes',
        appBar: 'AppBar',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_RECEBIMENTO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_RECEBIMENTO.name,
        view: 'recebimento',
        appBar: 'AppBar',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_RECEBIMENTO_RESUMO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_RECEBIMENTO_RESUMO.name,
        view: 'recebimento_resumo',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_RECEBIMENTO_ID.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_RECEBIMENTO_ID.name,
        view: 'recebimento_id',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_PRODUCAO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_PRODUCAO.name,
        view: 'producao',
        appBar: 'AppBar',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_APONTAMENTO_PESAGEM.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_APONTAMENTO_PESAGEM.name,
        view: 'producao_pesagem',
        appBar: 'AppBar',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_FILA.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_FILA.name,
        view: 'fila',
        appBar: 'AppBar',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_ESTOQUE.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_ESTOQUE.name,
        view: 'estoque',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CONSULTA.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CONSULTA.name,
        view: 'consulta',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CADASTRO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CADASTRO.name,
        view: 'cadastro',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_IMAGENS.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_IMAGENS.name,
        view: 'imagens',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CAMERA.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CAMERA.name,
        view: 'camera',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_QR.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_QR.name,
        view: 'camera_qr',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_IMGLOTE.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_IMGLOTE.name,
        appBar: 'AppBar',
        view: 'imglote',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_IMGCONTRATO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_IMGCONTRATO.name,
        appBar: 'AppBar',
        filters: 'Filters',
        view: 'imgcontratomp',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_IMGINVOICE.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_IMGINVOICE.name,
        appBar: 'AppBar',
        view: 'imginvoice',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_IMGOS.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_IMGOS.name,
        appBar: 'AppBar',
        view: 'imgordemservico',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_LOTES.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_LOTES.name,
        appBar: 'AppBar',
        view: 'lotes',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_LOTES_ID.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_LOTES_ID.name,
        view: 'lotes_id',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_FIN_PAGAR.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_FIN_PAGAR.name,
        view: 'apagar',
        appBar: 'AppBar',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_FIN_RECEBER.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_FIN_RECEBER.name,
        view: 'areceber',
        appBar: 'AppBar',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_FLUXO_DE_CAIXA.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_FLUXO_DE_CAIXA.name,
        view: 'fluxodecaixa',
        appBar: 'AppBar',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    redirectRoute(ROUTES.ROUTE_HOME),
  ],
});

export default router;
